import { createAction } from '@reduxjs/toolkit';
import { roles } from '../../assets/roles';
import { ApiService } from '../middleware/api';

export const GET_ALL_USERS_SUCCESS = createAction('users/GET_ALL_USERS.SUCCESS');
export const GET_ALL_USERS_PENDING = createAction('users/GET_ALL_USERS.PENDING');
export const GET_ALL_USERS_FAILURE = createAction('users/GET_ALL_USERS.FAILURE');
export const fetchAllUsers =
  (
    {
      page = '',
      offset = '',
      role,
      addRole,
      skipRole = '',
      status,
      nameFilter,
      specializationIdArrayFilter,
      activeStatus,
      userRoles = roles,
      grade,
      vacancy,
      noVacancy,
      vacancyStatus,
      refusalReason,
      assignee,
      candidateAttribute,
    },
    onSuccess,
  ) =>
  async (dispatch) => {
    dispatch(GET_ALL_USERS_PENDING());
    try {
      const allRoles = Object.keys(userRoles).join(',');
      const filterByRole = role === 'ALL' ? allRoles : role;
      const filterByAdditionalRole = addRole ? `&addRole=${addRole}` : '';
      const filterByRoleExceptOne = skipRole ? `&skipRole=${skipRole}` : '';
      const filterByStatus = status ? `&status=${status}` : '';
      const filterByName = nameFilter ? `&name=${nameFilter}` : '';
      const filterSpecializationIdArrayFilter = specializationIdArrayFilter
        ? `&specialization=${specializationIdArrayFilter}`
        : [];
      const filterByActive = activeStatus ? `&activeStatus=${activeStatus}` : '';
      const filterByGrade = grade ? `&grade=${grade}` : '';
      const filterByVacancy = vacancy ? `&vacancy=${vacancy}` : '';
      const filterByVacancyStatus = vacancyStatus ? `&vacancyStatus=${vacancyStatus}` : '';
      const filterNoVacancy = noVacancy ? `&noVacancy=${noVacancy}` : '';
      const filterByRefusalReason = refusalReason ? `&refusalReason=${refusalReason}` : '';
      const filterByAssignee = assignee ? `&assignee=${assignee}` : '';
      const filterByAttribute = candidateAttribute ? `&candidateAttribute=${candidateAttribute}` : '';

      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `users?page=${page}&offset=${offset}&role=${filterByRole}${filterByName}${filterSpecializationIdArrayFilter}${filterByActive}${filterByStatus}${filterByGrade}${filterByRoleExceptOne}${filterByVacancy}${filterByVacancyStatus}${filterNoVacancy}${filterByRefusalReason}${filterByAssignee}${filterByAdditionalRole}${filterByAttribute}`,
      });
      dispatch(GET_ALL_USERS_SUCCESS(data));
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      dispatch(GET_ALL_USERS_FAILURE(err));
    }
  };

export const GET_RECRUITERS_SUCCESS = createAction('users/GET_RECRUITERS.SUCCESS');
export const fetchAllRecruiters = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users?role=RECRUITER',
    });
    dispatch(GET_RECRUITERS_SUCCESS(data));
  } catch (err) {}
};

export const GET_ACTIVE_USER_ID_SUCCESS = createAction('users/GET_ACTIVE_USER_ID.SUCCESS');
export const fetchActiveUserId = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/me',
    });
    dispatch(GET_ACTIVE_USER_ID_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_USER_SUCCESS = createAction('users/CREATE_USER.SUCCESS');
export const CREATE_USER_FAILURE = createAction('users/CREATE_USER.FAILURE');
export const createUser = (userData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `users`,
      query: {
        user: {
          ...userData,
        },
      },
    });
    dispatch(CREATE_USER_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_USER_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CHANGE_USER_PASSWORD_SUCCESS = createAction('users/CHANGE_USER_PASSWORD.SUCCESS');
export const CHANGE_USER_PASSWORD_FAILURE = createAction('users/CHANGE_USER_PASSWORD.FAILURE');
export const changeUserPassword = (id, oldPassword, newPassword, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/password/${id}`,
      query: {
        oldPassword,
        password: newPassword,
      },
    });
    dispatch(CHANGE_USER_PASSWORD_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CHANGE_USER_PASSWORD_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CHANGE_USERT_PARAMETER = createAction('users/CHANGE_USERT_PARAMETER');
export const changeUserParameter = (id, parameter, value) => (dispatch) => {
  dispatch(
    CHANGE_USERT_PARAMETER({
      payload: {
        id,
        parameter,
        value,
      },
    }),
  );
};

export const DELETE_USER_SUCCESS = createAction('users/DELETE_USER.SUCCESS');
export const deleteUser = (id) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `users/${id}`,
    });
    dispatch(DELETE_USER_SUCCESS(data));
  } catch (err) {}
};

export const INCREASE_USERS_LENGTH = createAction('users/INCREASE_USERS_LENGTH');
export const increaseUsersLength = () => (dispatch) => {
  dispatch(INCREASE_USERS_LENGTH());
};

export const CHANGE_USER_STATUS_SUCCESS = createAction('users/CHANGE_USER_STATUS.SUCCESS');
export const CHANGE_USER_STATUS_FAILURE = createAction('users/CHANGE_USER_STATUS.FAILURE');
export const changeUserStatus = (id, status) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/active/status/${id}`,
      query: {
        status: status,
      },
    });
    dispatch(CHANGE_USER_STATUS_SUCCESS(data));
  } catch (err) {
    dispatch(CHANGE_USER_STATUS_FAILURE(err));
  }
};
export const CHANGE_USER_ROLE_SUCCESS = createAction('users/CHANGE_USER_ROLE.SUCCESS');
export const CHANGE_USER_ROLE_FAILURE = createAction('users/CHANGE_USER_ROLE.FAILURE');
export const changeUserRole = (id, user, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/role/${id}`,
      query: user,
    });
    dispatch(CHANGE_USER_ROLE_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CHANGE_USER_ROLE_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CHANGE_CANDIDATE_STATUS_SUCCESS = createAction('users/CHANGE_CANDIDATE_STATUS.SUCCESS');
export const CHANGE_CANDIDATE_STATUS_FAILURE = createAction('users/CHANGE_CANDIDATE_STATUS.FAILURE');
export const changeCandidateStatus = (id, user, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PATCH',
      endpoint: `users/status/${id}`,
      query: user,
    });
    dispatch(CHANGE_CANDIDATE_STATUS_SUCCESS(data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    dispatch(CHANGE_CANDIDATE_STATUS_FAILURE(err));
    if (onFailure) {
      onFailure(err?.response?.data?.error);
    }
  }
};

export const CHANGE_USER_INFO_SUCCESS = createAction('users/CHANGE_USER_INFO.SUCCESS');
export const CHANGE_USER_INFO_FAILURE = createAction('users/CHANGE_USER_INFO.FAILURE');
export const changeUserInfo = (id, user, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/${id}`,
      query: user,
    });
    dispatch(CHANGE_USER_INFO_SUCCESS(data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    dispatch(CHANGE_USER_INFO_FAILURE(err));
    if (onFailure) {
      onFailure(err?.response?.data?.error);
    }
  }
};

export const GET_USERS_STATISTICS_SUCCESS = createAction('users/GET_USERS_STATISTICS.SUCCESS');
export const fetchUsersStatistics = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/users-statistics',
    });
    dispatch(GET_USERS_STATISTICS_SUCCESS(data));
  } catch (err) {}
};

export const GET_USERS_BIRTHDAYS_SUCCESS = createAction('users/GET_USERS_BIRTHDAYS.SUCCESS');
export const fetchUsersBirthdays = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/birthdays',
    });
    dispatch(GET_USERS_BIRTHDAYS_SUCCESS(data));
  } catch (err) {}
};

export const GET_WORK_ANNIVERSARY = createAction('users/GET_WORK_ANNIVERSARY');
export const fetchWorkAnniversary = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: '/users/work-anniversary',
    });
    dispatch(GET_WORK_ANNIVERSARY(data));
  } catch (err) {}
};

export const RESET_USER_PASSWORD_SUCCESS = createAction('users/RESET_USER_PASSWORD.SUCCESS');
export const resetUserPassword = (id, password) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/reset-password/${id}`,
      query: { newPassword: password },
    });
    dispatch(RESET_USER_PASSWORD_SUCCESS(data));
  } catch (err) {}
};

export const CHANGE_USER_SUCCESS_MESSAGE = createAction('users/CHANGE_USER_SUCCESS_MESSAGE');
export const changeUserSuccessMessage = (payload) => (dispatch) => {
  dispatch(CHANGE_USER_SUCCESS_MESSAGE(payload));
};

export const CLEAR_CHANGE_USER_ERROR = createAction('users/CLEAR_CHANGE_USER_ERROR');
export const clearChangeUserError =
  (payload = '') =>
  (dispatch) => {
    dispatch(CLEAR_CHANGE_USER_ERROR(payload));
  };

export const GET_USER_BY_ID_SUCCESS = createAction('users/GET_USER_BY_ID.SUCCESS');
export const GET_USER_BY_ID_PENDING = createAction('users/GET_USER_BY_ID.PENDING');
export const GET_USER_BY_ID_FAILURE = createAction('users/GET_USER_BY_ID.FAILURE');

export const getUserById = (id, onSuccess) => async (dispatch) => {
  dispatch(GET_USER_BY_ID_PENDING());

  try {
    const { data } = await ApiService.apiCall({
      endpoint: `users/${id}`,
      method: 'GET',
    });
    dispatch(GET_USER_BY_ID_SUCCESS(data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    dispatch(GET_USER_BY_ID_FAILURE(err));
  }
};

export const GET_USERS_BY_VACANCY_ID_SUCCESS = createAction('vacancies/GET_USERS_BY_VACANCY_ID.SUCCESS');
export const getUsersByVacancyId = (id) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/vacancy/${id}`,
    });
    dispatch(GET_USERS_BY_VACANCY_ID_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_USERS = createAction('users/CLEAR_USERS');
export const clearUsers =
  (payload = []) =>
  (dispatch) => {
    dispatch(CLEAR_USERS(payload));
  };

export const UPLOAD_AVATAR_SUCCESS = createAction('users/UPLOAD_AVATAR.SUCCESS');
export const uploadAvatar = (selectedFile) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('avatar', selectedFile);

    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `users/avatar`,
      query: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    dispatch(UPLOAD_AVATAR_SUCCESS(data));
  } catch (err) {}
};

export const GET_AVATAR_SUCCESS = createAction('users/GET_AVATAR.SUCCESS');
export const fetchUserAvatar = (key) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/avatar/${key}`,
    });
    dispatch(GET_AVATAR_SUCCESS(data));
  } catch (err) {}
};

export const ADD_DOCUMENT_SUCCESS = createAction('users/ADD_DOCUMENT.SUCCESS');
export const ADD_DOCUMENT_FAILURE = createAction('users/ADD_DOCUMENT.FAILURE');
export const addDocument = (newDocumentData, userId, newDocumentFile, onSuccess, onFailure) => async (dispatch) => {
  try {
    const formData = new FormData();
    if (newDocumentFile) {
      formData.append('document', newDocumentFile);
    } else {
      formData.append('link', newDocumentData.link);
    }
    formData.append('name', newDocumentData.name);
    formData.append('description', newDocumentData.description);
    formData.append('type', newDocumentData.type);
    formData.append('visibility', newDocumentData.visibility);
    formData.append('userId', userId);

    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'users/document',
      query: formData,
      body: newDocumentData,
    });
    dispatch(ADD_DOCUMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(ADD_DOCUMENT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_DOCUMENT_SUCCESS = createAction('users/DELETE_DOCUMENT.SUCCESS');
export const DELETE_DOCUMENT_FAILURE = createAction('users/DELETE_DOCUMENT.FAILURE');
export const deleteDocument = (documentId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `users/document/${documentId}`,
    });
    dispatch(DELETE_DOCUMENT_SUCCESS(data));

    onSuccess();
  } catch (err) {
    dispatch(DELETE_DOCUMENT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const EDIT_DOCUMENT_SUCCESS = createAction('users/EDIT_DOCUMENT.SUCCESS');
export const EDIT_DOCUMENT_FAILURE = createAction('users/EDIT_DOCUMENT.FAILURE');
export const editDocument = (newDocumentData, documentId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/document/${documentId}`,
      query: newDocumentData,
    });
    dispatch(EDIT_DOCUMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(EDIT_DOCUMENT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const downloadCandidatesToFile = (onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/download-candidates',
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'candidates.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};
