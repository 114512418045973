import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state?.auth;

export const getError = createSelector(getState, (auth) => auth?.error?.message);
export const getValidateLinkError = createSelector(getState, (auth) => auth?.validateLinkError?.message);
export const getIsPending = createSelector(getState, (auth) => auth?.isPending);

export const getUserId = createSelector(getState, (auth) => auth?.currentUser?._id);
export const getCurrentUser = createSelector(getState, (auth) => auth?.currentUser);
export const getUserRole = createSelector(getState, (auth) => auth?.currentUser?.role);
export const getUserAdditionRole = createSelector(getState, (auth) => auth?.currentUser?.additionRole);
export const getResetPasswordInfo = createSelector(getState, (auth) => auth?.resetPasswordInfo);
