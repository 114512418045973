import i18next from 'i18next';

export const taskStatuses = {
  BACKLOG: 'BACKLOG',
  TODO: 'TODO',
  IN_PROGRESS: 'IN_PROGRESS',
  TESTING: 'TESTING',
  DONE: 'DONE',
  FAILED: 'FAILED',
  CURRENT: 'CURRENT',
  ALL: 'ALL',
};

export const taskStatusesForRequest = {
  BACKLOG: 'BACKLOG',
  TODO: 'TODO',
  IN_PROGRESS: 'IN_PROGRESS',
  TESTING: 'TESTING',
  DONE: 'DONE',
  FAILED: 'FAILED',
  CURRENT: ['TODO', 'IN_PROGRESS', 'TESTING'],
  ALL: ['TODO', 'IN_PROGRESS', 'TESTING', 'DONE', 'FAILED'],
};

export const filterStatusesALL = ['TODO', 'IN_PROGRESS', 'TESTING', 'DONE', 'FAILED'];
export const filterStatusesCURRENT = ['TODO', 'IN_PROGRESS', 'TESTING'];

export const taskStatusesList = ['BACKLOG', 'TODO', 'IN_PROGRESS', 'TESTING', 'DONE', 'FAILED'];
export const taskBoardStatusesList = ['TODO', 'IN_PROGRESS', 'TESTING', 'DONE', 'FAILED'];

export const taskStatusesListLabelForBoard = () => ({
  TODO: i18next.t('projectTaskStatusTodo'),
  IN_PROGRESS: i18next.t('projectTaskStatusInProgress'),
  TESTING: i18next.t('projectTaskStatusTesting'),
  DONE: i18next.t('projectTaskStatusDone'),
  FAILED: i18next.t('projectTaskStatusFailed'),
});
export const taskPriorityList = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
export const filterStatusesList = ['ALL', 'CURRENT', 'DONE'];

export const taskStatusesLabel = {
  BACKLOG: 'projectTaskStatusBacklog',
  TODO: 'projectTaskStatusTodo',
  IN_PROGRESS: 'projectTaskStatusInProgress',
  TESTING: 'projectTaskStatusTesting',
  DONE: 'projectTaskStatusDone',
  FAILED: 'projectTaskStatusFailed',
  ALL: 'projectTaskStatusAll',
  CURRENT: 'projectTaskStatusCurrent',
};

export const taskSpecialType = {
  SICK_DAY: 'SICK_DAY',
  DAY_OFF: 'DAY_OFF',
  VACATION: 'VACATION',
  PRE_SALE: 'PRE_SALE',
  BENCH: 'BENCH',
  INTERVIEWS: 'INTERVIEWS',
};

export const taskSpecialTypeLabels = () => ({
  SICK_DAY: i18next.t('taskSpecialTypeSickDay'),
  DAY_OFF: i18next.t('taskSpecialTypeDayOff'),
  VACATION: i18next.t('taskSpecialTypeVacation'),
  PRE_SALE: i18next.t('taskSpecialTypePreSale'),
  BENCH: i18next.t('taskSpecialTypeBench'),
  INTERVIEWS: i18next.t('taskSpecialTypeInterviews'),
});

export const taskPriorityLabels = {
  LOW: 'taskPriorityLow',
  MEDIUM: 'taskPriorityMedium',
  HIGH: 'taskPriorityHigh',
  CRITICAL: 'taskPriorityCritical',
};

export const canTrackTime = (task) => {
  return ![taskStatuses.DONE].includes(task.columnId.status);
};
